import React from "react"

const QuestionAndAnswer = ({ children, question, isOpen=false }) => {
  return (
    <>
      <details open={isOpen}>
        <summary>{question}</summary>
        <p>
          {children}
        </p>
      </details>
    </>
  )
}

export default QuestionAndAnswer
