import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"

// Note:
// do not use Link component in cards, as activeStyle will fuck up Landkit styling

// takes an array of blog posts (allMarkdownRemark)
// otherwise defaults to displaying the 3 latest blog posts
export default function MoreBlogPosts({ posts, caption }) {
  var blogPosts = []
  const componentCaption = !!caption ? caption : "Latest Blog Posts"

  var defaultBlogPosts = useStaticQuery(
    graphql`
      query MoreBlogPostsQuery {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { glob: "**/src/markdown-pages/blog/*.md" }
          }
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 3
        ) {
          edges {
            node {
              frontmatter {
                path
                title
                date(formatString: "DD MMMM YYYY")
                teaser
              }
            }
          }
        }
      }
    `
  )

  if (!!posts) {
    // blog posts have been handed in via properties
    blogPosts = posts.allMarkdownRemark.edges
  } else {
    // no blog posts have been handed in, fall back to default
    blogPosts = defaultBlogPosts.allMarkdownRemark.edges
  }

  return (
    <section className="pt-7 pt-md-10 pb-5">
      <div className="container">
        <div className="row align-items-center mb-5">
          <div className="col-12 col-md">
            <h3 className="mb-0">{componentCaption}</h3>
          </div>
          <div className="col-12 col-md-auto">
            <Link
              to="/blog/"
              className="btn btn-sm btn-outline-gray-300 d-none d-md-inline"
            >
              View all
            </Link>
          </div>
        </div>
        <div className="row">
          {blogPosts.map(({ node: post }, index) => {
            return (
              <div className="col-12 col-md-6 col-lg-4 d-flex" key={index}>
                <div
                  className="card card-border mb-6 mb-lg-0 shadow-lift lift lift-lg"
                  style={{ borderTopColor: "#343a40" }}
                >
                  <div className="card-body h-100">
                    <h3>{post.frontmatter.title}</h3>
                    <p className="mb-0 text-muted small">
                      {post.frontmatter.teaser}
                    </p>
                    <a
                      href={post.frontmatter.path}
                      className="stretched-link"
                      rel="noopener noreferrer"
                      aria-label={post.frontmatter.title}
                    >{" "}</a>
                  </div>
                  <div className="card-meta mt-auto">
                    <hr className="card-meta-divider" />
                    <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                      <time dateTime={post.frontmatter.date}>
                        {post.frontmatter.date}
                      </time>
                    </p>
                    <a
                      href={post.frontmatter.path}
                      className="stretched-link"
                      rel="noopener noreferrer"
                      aria-label={post.frontmatter.title}
                    >{" "}</a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
